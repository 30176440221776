import { Component, OnInit } from '@angular/core';
import { Project } from '../_models/project';
import IndividualProjectJson from '../../assets/projects/individual.json';
import MiscProjectJson from '../../assets/projects/misc.json';
import StarPalaceProjectJson from '../../assets/projects/starpalace.json';
import ConfidentialProjectJson from '../../assets/projects/confidential.json';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  individualProjects: Project[] = new Array<Project>();
  starPalaceProjects: Project[] = new Array<Project>();
  miscProjects: Project[] = new Array<Project>();
  confidentialProjects: Project[] = new Array<Project>();


  activeProjects: Project[] = new Array<Project>();
  activePage: string;
  breakpoint: number;

  screenWidth: number;

  mapping: Map<any, Project[]> = new Map<any, Project[]>();


  constructor() {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    this.mapping.set(IndividualProjectJson, this.individualProjects);
    this.mapping.set(MiscProjectJson, this.miscProjects);
    this.mapping.set(ConfidentialProjectJson, this.confidentialProjects);
    this.mapping.set(StarPalaceProjectJson, this.starPalaceProjects);
  }

  ngOnInit() {
    this.load();
    this.loadIndividual();

  }

  onResize(event) {

  }

  loadIndividual() {
    this.activeProjects = this.individualProjects;
    this.activePage = ' > Personal';
  }
  loadStarPalace() {
    this.activeProjects = this.starPalaceProjects;
    this.activePage = ' > Star Palace';
  }
  loadMisc() {
    this.activeProjects = this.miscProjects;
    this.activePage = ' > Misc';
  }

  loadConfidential() {
    this.activeProjects = this.confidentialProjects;
    this.activePage = ' > Confidential';
  }

  load() {
    IndividualProjectJson.forEach(element => {
      this.individualProjects.push(
        new Project(element.title,
          element.start,
          element.end,
          element.description,
          element.image,
          element.link,
          element.tags,
          element.repo
        )
      );
    });
    MiscProjectJson.forEach(element => {
      this.miscProjects.push(
        new Project(element.title,
          element.start,
          element.end,
          element.description,
          element.image,
          element.link,
          element.tags,
          element.repo
        )
      );
    });
    StarPalaceProjectJson.forEach(element => {
      this.miscProjects.push(
        new Project(element.title,
          element.start,
          element.end,
          element.description,
          element.image,
          element.link,
          element.tags,
          element.repo
        )
      );
    });
    ConfidentialProjectJson.forEach(element => {
      this.confidentialProjects.push(
        new Project(element.title,
          element.start,
          element.end,
          element.description,
          element.image,
          element.link,
          element.tags,
          element.repo
        )
      );
    });
  }

}
