import moment from 'moment';


export class Project {
    title: string;
    shortDescription = '';
    description: string[];
    image: string;
    link: string;
    tags: string[];
    repo: string;
    private start: string;
    private end: string;

    constructor(title: string, start: string, end: string,
                description: string[], image: string, link: string, tags: string[], repo: string) {
        this.title = title;
        this.start = start;
        this.end = end;
        this.description = description;
        this.image = image;
        this.link = link;
        this.tags = tags;
        this.repo = repo;
        this.generate_shortdescription();
    }

    generate_shortdescription(): void {
        // let startTime = moment().format("YYYY-MM-DD");
        let startTime: string;
        let endTime: string;
        if (this.start === 'Now' || this.start === 'now') {
            startTime = 'Now';
        } else {
            startTime = moment(this.start, 'YYYY-MM-DD').format('MMM, YYYY');
        }
        if (this.end === 'Now' || this.end === 'now') {
            endTime = 'Now';
        } else {
            endTime = moment(this.end, 'YYYY-MM-DD').format('MMM, YYYY');
        }
        if (startTime === endTime) {
            this.shortDescription = startTime;
        } else {
            this.shortDescription = startTime + ' - ' + endTime;
        }
    }

}
