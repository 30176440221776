import { Component, OnInit } from '@angular/core';
import LeetCodeSchema from '../../assets/dashboard/leetcode/schema.json';
import LeetCodeData from '../../assets/dashboard/leetcode/data.json';
import FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dataSource: any;
  type: string;
  width: string;
  height: string;

  constructor() {
    this.type = 'timeseries';
    this.width = '100%';
    this.height = '400';
    this.dataSource = {
      data: null,
      chart: {
        showLegend: 1
      },
      caption: {
        text: 'LeetCode Progress'
      },
      series: 'Difficulty',
      yAxis: [
        {
          plot: [
            {
              value: 'Count',
              type: 'area',
              style: {
                plot: {
                  'fill-opacity': '0.3'
                }
              }
            }
          ]
        }
      ]
    };
    this.fetchData();
  }

  ngOnInit() {
  }

  fetchData() {
    Promise.all([LeetCodeData, LeetCodeSchema]).then(res => {
      const data = res[0];
      const schema = res[1];
      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      this.dataSource.data = fusionTable;
    });
  }

}
