import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lastwords',
  templateUrl: './lastwords.component.html',
  styleUrls: ['./lastwords.component.css']
})
export class LastwordsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
