import { NgModule } from '@angular/core';

import {
    MatToolbarModule,
    MatExpansionModule,
    MatFormFieldModule, MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatSidenavModule,
    MatGridListModule,
    MatIconRegistry,
    MatDialogModule
} from '@angular/material';

import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
    imports: [
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatGridListModule,
        MatDialogModule,
    ],
    exports: [
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatGridListModule,
        MatDialogModule
    ]
})

export class MatModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
     }
 }
