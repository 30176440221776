
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';


import { MatModule } from './material.module';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectComponent } from './project/project.component';
import { UniversityComponent } from './university/university.component';
import { TopbarComponent } from './topbar/topbar.component';
import { ResumeComponent } from './resume/resume.component';
import { ImagePopupComponent } from './imagePopup/imagePopup.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { LastwordsComponent } from './lastwords/lastwords.component';

FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme, TimeSeries);

@NgModule({
   declarations: [
      AppComponent,
      TopbarComponent,
      ResumeComponent,
      UniversityComponent,
      ProjectsComponent,
      ProjectComponent,
      ImagePopupComponent,
      HomeComponent,
      DashboardComponent,
      LastwordsComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatModule,
      FlexLayoutModule,
      FusionChartsModule
   ],
   entryComponents: [
      ImagePopupComponent
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule {
   
}
