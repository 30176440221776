import { Component, OnInit } from '@angular/core';
import { University } from '../_models/university';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImagePopupComponent, DialogData } from '../imagePopup/imagePopup.component';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  universities: University[] = [
    new University('Shanghai Jiao Tong University', new Date(2008, 8), new Date(2012, 5),
      'Shanghai', 'Mechanical Engineering'),
    new University('Illinois Institute of Techonology', new Date(2013, 0), new Date(2014, 11),
      'Chicago', 'Aerospace Engineering'),
    new University('University of Illinois at Urbana Champaign', new Date(2018, 0), new Date(2019, 11),
      'Shanghai', 'Computer Science, Data Science')
  ];

  companies: University[] = [
    new University('Schlumberger Oilfield Service', new Date(2015, 6), new Date(2018, 7),
      'Shanghai', 'Sustaining Engineer'),
    new University('Shiheng Technology Service', new Date(2018, 7), new Date(2018, 10),
      'Shanghai', 'Big Data Engineer'),
    new University('CAS-Nova & Space-OK Satellite Technology', new Date(2019, 2), new Date(),
      'Shanghai', 'Software Engineer')
  ];

  pythonSkills: string[] = ['python', 'sklearn', 'OpenCV', 'flask', 'pytorch'];
  csharpSkills: string[] = ['C#', '.Net Core', 'ASP.Net Core', 'WPF'];
  databaseSkills: string[] = ['MySQL', 'PostgreSQL', 'MongoDB', 'Redis'];
  cloudSkills: string[] = ['Hadoop', 'Hive', 'Kafka', 'Spark'];
  webSkills: string[] = ['Angular', 'd3.js'];

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, public dialog: MatDialog) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }

  ngOnInit() {
  }

  openDialog(): void{
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      data: { title: 'WECHAT QR CODE', image: '../../assets/wechat.jpg' }
    });
  }

}
