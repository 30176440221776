export class University{
    name: string;
    start: Date;
    end: Date;
    city: string;
    major: string;
    constructor(name: string, start: Date, end: Date, city:string, major:string) {
        this.name = name;
        this.start = start;
        this.end = end;
        this.city = city;
        this.major = major;
    }
}