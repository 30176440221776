import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumeComponent } from './resume/resume.component';
import { ProjectsComponent } from './projects/projects.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LastwordsComponent } from './lastwords/lastwords.component';



const routes: Routes = [
   { path: 'about', component: ResumeComponent },
   { path: 'projects', component: ProjectsComponent },
   { path: 'dashboard', component: DashboardComponent },
   { path: 'lastwords', component: LastwordsComponent},
   { path: '**', component: ProjectsComponent }
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes)
   ],
   exports: [
      RouterModule
   ],
   declarations: [
   ]
})
export class AppRoutingModule { }
