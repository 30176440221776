import { Component, OnInit, Input } from '@angular/core';
import { University } from '../_models/university';

@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit {

  @Input() university: University;

  constructor() {
    this.university = new University('XX', new Date(2019, 8), new Date(2019, 9), '~', '!!');
   }

  ngOnInit() {
  }

}
